import React from 'react';
import { Link } from '@fluentui/react-components';
import config from '../../config/config';

const PowerBI = () => {
  return (
    <div style={styles.container}>
      <div style={styles.linkBox}>
        <img 
          src="/assets/powerbi.jpg" 
          alt="Power BI" 
          style={styles.icon} 
        />
        <Link 
          href={config.poweBi} 
          target="_blank" 
          rel="noopener noreferrer" 
          style={styles.link}
        >
          Performance Hub Reports
        </Link>
      </div>
    </div>
  );
};

const styles = {
  container: {
    height: '100vh',            
    display: 'flex',            
    justifyContent: 'center',   
    alignItems: 'center',       
    backgroundColor: '#f7f7f7',
  },
  linkBox: {
    padding: '14px',
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    display: 'flex',            
    alignItems: 'center',       
    gap: '1px',                
    textAlign: 'center',
  },
  icon: {
    width: '110px',
    height: '60px',
  },
  link: {
    fontSize: '16px',
    color: '#0078d4',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
};

export default PowerBI;
