import React, { useState, useEffect, useContext, useCallback } from "react";
import { TeamsFxContext } from "../Context";
import { Navigate, useLocation } from "react-router-dom";
import {
  TableCellLayout,
  Label,
  DataGrid,
  DataGridBody,
  DataGridRow,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridCell,
  createTableColumn,
  SearchBox,
  Spinner,
  MessageBar,
} from "@fluentui/react-components";
import FilterDropdown from './FilterDropdown';
import { IconButton, Text, PrimaryButton } from "@fluentui/react";
import AddUserModal from "../modals/UserModal";
import { ChevronDown20Regular } from "@fluentui/react-icons";
import axios from "axios";

const UserProfile = () => {
  const { teamsUserCredential, apiClient, isMobile } = useContext(TeamsFxContext);

  console.log(isMobile);
  const location = useLocation();

  const [userInfo, setUserInfo] = useState({});
  const [items, setItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [selectedUser, setSelectedUser] = useState(null);
  const [navigateToDetail, setNavigateToDetail] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [dropdownValues, setDropdownValues] = useState({
    userroles: [],
    plants: [],
    areas: [],
    departments: [],
    subareas: []
  });
  const [appliedFilters, setAppliedFilters] = useState({
    shifts: [],
    footprints: [],
    users: [],
    downtimeMin: "",
    downtimeMax: "",
    alarmMin: "",
    alarmMax: ""
  });
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  useEffect(() => {
    const initUserInfo = async () => {
      try {
        const userInfo = await teamsUserCredential.getUserInfo();
        setUserInfo(userInfo);

        // Always fetch dropdown values first
        const values = await apiClient.get('getDefaultValues');
        setDropdownValues(values.data.data);

        const state = location.state;
        if (state?.items) {
          setItems(state.items);
          setAppliedFilters(state.appliedFilters);
          setCurrentPage(state.currentPage || 1);
          setSearchTerm(state.searchTerm || "");
        } else {
          await fetchItems();
        }
      } catch (err) {
        console.error("Error initializing or fetching data:", err);
        setErrorMessage("Error fetching data. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };
    initUserInfo();
  }, [teamsUserCredential, location.state]);

  const getUserRoleName = (roleId) => {
    const role = dropdownValues.userroles.find(r => r.userrole_id === roleId);
    return role ? role.userrole_name : roleId;
  };

  const getPlantName = (plantId) => {
    const plant = dropdownValues.plants.find(p => p.plant_id === plantId);
    return plant ? plant.plant_cd : plantId;
  };

  const getAreaName = (areaId) => {
    const area = dropdownValues.areas.find(a => a.area_id === areaId);
    return area ? area.area : areaId;
  };

  const getDepartmentName = (deptId) => {
    const dept = dropdownValues.departments.find(d => d.department_id === deptId);
    return dept ? dept.department : deptId;
  };

  const getSubAreaName = (subareaId) => {
    const subarea = dropdownValues.subareas.find(s => s.subarea_id === subareaId);
    return subarea ? subarea.sub_area : subareaId;
  };

  const createColumns = () => [
    { id: "gmin", label: "GMIN ID " },
    { id: "name", label: "User Name " },
    { id: "user_email", label: "User Email " },
    {
      id: "userrole_id",
      label: "User Role ",
      renderCell: (item) => (
        <TableCellLayout>
          {Array.isArray(item.userrole_id)
            ? item.userrole_id
                .filter(roleId => roleId !== null && roleId !== undefined)
                .map(roleId => getUserRoleName(roleId))
                .filter(name => name)
                .join(', ')
            : getUserRoleName(item.userrole_id)}
        </TableCellLayout>
      )
    },
    {
      id: "plant_id",
      label: "Plant ",
      renderCell: (item) => (
        <TableCellLayout>
          {Array.isArray(item.plant_id)
            ? item.plant_id
                .filter(plantId => plantId !== null && plantId !== undefined)
                .map(plantId => getPlantName(plantId))
                .filter(name => name)
                .join(', ')
            : getPlantName(item.plant_id)}
        </TableCellLayout>
      )
    },
    {
      id: "area_id",
      label: "Area ",
      renderCell: (item) => (
        <TableCellLayout>
          {Array.isArray(item.area_id)
            ? item.area_id
                .filter(areaId => areaId !== null && areaId !== undefined)
                .map(areaId => getAreaName(areaId))
                .filter(name => name)
                .join(', ')
            : getAreaName(item.area_id)}
        </TableCellLayout>
      )
    },
    {
      id: "department_id",
      label: "Department ",
      renderCell: (item) => (
        <TableCellLayout>
          {Array.isArray(item.department_id)
            ? item.department_id
                .filter(deptId => deptId !== null && deptId !== undefined)
                .map(deptId => getDepartmentName(deptId))
                .filter(name => name)
                .join(', ')
            : getDepartmentName(item.department_id)}
        </TableCellLayout>
      )
    },
    {
      id: "shift",
      label: "Shift ",
      renderCell: (item) => {
        const shiftMap = {
          1: '1',
          2: '2',
          3: '3'
        };
        return (
          <TableCellLayout>
            {Array.isArray(item.shift)
              ? item.shift
                  .filter(shiftId => shiftId !== null && shiftId !== undefined)
                  .map(shiftId => shiftMap[shiftId])
                  .filter(name => name)
                  .join(', ')
              : shiftMap[item.shift] || ''}
          </TableCellLayout>
        );
      }
    },
    {
      id: "subarea_id",
      label: "Sub Area ",
      renderCell: (item) => (
        <TableCellLayout>
          {Array.isArray(item.subarea_id)
            ? item.subarea_id
                .filter(subareaId => subareaId !== null && subareaId !== undefined)
                .map(subareaId => getSubAreaName(subareaId))
                .filter(name => name)
                .join(', ')
            : getSubAreaName(item.subarea_id)}
        </TableCellLayout>
      )
    },
    { id: "created_at", label: "Created at" },
  ].map((column) =>
    createTableColumn({
      columnId: column.id,
      compare: (a, b) => {
        if (Array.isArray(a[column.id])) {
          const aValue = a[column.id].find(val => val !== null && val !== undefined) || '';
          const bValue = Array.isArray(b[column.id]) 
            ? b[column.id].find(val => val !== null && val !== undefined) || ''
            : (b[column.id] || '');
          return String(aValue).localeCompare(String(bValue));
        } else if (Array.isArray(b[column.id])) {
          const aValue = a[column.id] || '';
          const bValue = b[column.id].find(val => val !== null && val !== undefined) || '';
          return String(aValue).localeCompare(String(bValue));
        }
        return String(a[column.id] || '').localeCompare(String(b[column.id] || ''));
      },
      renderHeaderCell: () => <span style={styles.headerCell}>{column.label}</span>,
      renderCell: column.renderCell || ((item) => <TableCellLayout>{item[column.id]}</TableCellLayout>),
    })
  );
  const handleFilterToggle = () => {
    setIsFilterOpen(prevIsFilterOpen => !prevIsFilterOpen);
  };

  const fetchItems = async () => {
    try {
      const response = await apiClient.get("userProfile");
      const values = await apiClient.get('getDefaultValues');
      setDropdownValues(values.data.data);

      const processedItems = processItems(response.data.users || []);
      setItems(processedItems);
    } catch (err) {
      console.error("Error fetching users:", err);
      setErrorMessage("Failed to fetch users. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const processItems = (items) => {
    const groupedItems = items.reduce((acc, item) => {
      const key = `${item.gmin}_${item.name}_${item.user_email}`;

      if (!acc[key]) {
        acc[key] = {
          ...item,
          userrole_id: [item.userrole_id],
          plant_id: [item.plant_id],
          area_id: [item.area_id],
          department_id: [item.department_id],
          shift: [item.shift],
          subarea_id: [item.subarea_id],
          allIds: [item.id]
        };
      } else {
        acc[key].userrole_id.push(item.userrole_id);
        acc[key].plant_id.push(item.plant_id);
        acc[key].area_id.push(item.area_id);
        acc[key].department_id.push(item.department_id);
        acc[key].shift.push(item.shift);
        acc[key].subarea_id.push(item.subarea_id);
        acc[key].allIds.push(item.id);
      }
      return acc;
    }, {});

    return Object.values(groupedItems);
  };



  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

const handleRowClick = (rowId) => {
  const selectedGrouped = items.find((item) => 
    Array.isArray(item.allIds) 
      ? item.allIds.includes(rowId)
      : item.id === rowId
  );

  const getOriginalRecords = async () => {
    try {
      const response = await apiClient.get("userProfile");
      const allRecords = response.data.users || [];
      const originalRecords = allRecords.filter(record => 
        selectedGrouped.allIds.includes(record.id)
      );
      
      setSelectedUser({
        ...selectedGrouped,
        originalRecords 
      });
      setNavigateToDetail(true);
    } catch (err) {
      console.error("Error fetching original records:", err);
    }
  };

  getOriginalRecords();
};

  const handleSearch = (event) => {
    setSearchTerm(event.target.value?.toLowerCase() || "");
    setCurrentPage(1);
  };

  const handlePagination = (direction) => {
    setCurrentPage((prevPage) => prevPage + direction);
  };


  const getFilteredItems = () => {
    const filteredItems = items.filter((item) => {
      const matchesSearch = Object.values(item).some((val) =>
        String(val).toLowerCase().includes(searchTerm.toLowerCase())
      );

      const matchesFilters =
        (appliedFilters.shifts?.length === 0 || appliedFilters.shifts?.includes(item.Shift)) &&
        (appliedFilters.footprints?.length === 0 || appliedFilters.footprints?.includes(item.Footprint)) &&
        (appliedFilters.users?.length === 0 || appliedFilters.users?.includes(item.User)) &&
        (appliedFilters.downtimeMin === "" || parseInt(item.TotalDowntime) >= parseInt(appliedFilters.downtimeMin)) &&
        (appliedFilters.downtimeMax === "" || parseInt(item.TotalDowntime) <= parseInt(appliedFilters.downtimeMax)) &&
        (appliedFilters.alarmMin === "" || parseInt(item.Alarms) >= parseInt(appliedFilters.alarmMin)) &&
        (appliedFilters.alarmMax === "" || parseInt(item.Alarms) <= parseInt(appliedFilters.alarmMax));

      return matchesSearch && matchesFilters;
    });

    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredItems.slice(startIndex, startIndex + itemsPerPage);
  };

  const handleApplyFilters = (filters) => {
    setAppliedFilters(filters);
  };

  const getTotalPages = () => Math.ceil(items.length / itemsPerPage);

  const handleSaveData = useCallback(
    async (saveData) => {
      try {
        const results = await Promise.all(
          saveData.updates.map(async (rowData) => {
            if (rowData.action === 'update') {
              await apiClient.put(`userProfile?id=${rowData.id}`, [rowData]);
              return { ...rowData, id: rowData.id };
            } else {
              const response = await apiClient.post("userProfile", [rowData]);
              return { ...rowData, id: response.data.id };
            }
          })
        );
  
        await fetchItems(); 
        setIsModalOpen(false);
      } catch (err) {
        console.error("Error saving data:", err);
        setErrorMessage("Failed to save data. Please try again.");
      }
    },
    [apiClient, fetchItems]
  );

  const renderPagination = () => {
    const totalPages = getTotalPages();
    return (
      <div style={styles.paginationContainer}>
        <IconButton
          iconProps={{ iconName: "ChevronLeftEnd6" }}
          ariaLabel="Go to first page"
          disabled={currentPage === 1}
          onClick={() => handlePagination(-currentPage + 1)}
          styles={styles.transparentButton}
        />
        <IconButton
          iconProps={{ iconName: "Back" }}
          ariaLabel="Go to previous page"
          disabled={currentPage === 1}
          onClick={() => handlePagination(-1)}
          styles={styles.transparentButton}
        />
        <Text style={styles.pageNumberText}>Page {currentPage} </Text>
        <IconButton
          iconProps={{ iconName: "Forward" }}
          ariaLabel="Go to next page"
          disabled={currentPage === totalPages}
          onClick={() => handlePagination(1)}
          styles={styles.transparentButton}
        />
        <IconButton
          iconProps={{ iconName: "ChevronRightEnd6" }}
          ariaLabel="Go to last page"
          disabled={currentPage === totalPages}
          onClick={() => handlePagination(totalPages - currentPage)}
          styles={styles.transparentButton}
        />
      </div>
    );
  };

  if (navigateToDetail) {
    return (
      <Navigate
        to="/user-detail"
        state={{
          user: selectedUser,
          currentPage,
          searchTerm,
          items,
          currentuser: userInfo.displayName,
          appliedFilters,
          dropdownValues 
        }}
        replace
      />
    );
  }

  return (
    <div style={{ padding: "20px", background: "white" }}>
      {isLoading && <Spinner label="Loading users..." size="large" />}
      {!isLoading && errorMessage && (
        <MessageBar intent="error" isMultiline={false} dismissButtonAriaLabel="Close">
          {errorMessage}
        </MessageBar>
      )}
      {!isLoading && !errorMessage && (
        <>
          <div style={styles.headerContainer}>
            <Label style={{ fontWeight: 400, fontSize: "18px", textAlign: "left", fontFamily: "Roboto", color: "#3B3B39" }}>User Profiles</Label>
            <div style={styles.searchContainer}>
              <SearchBox
                onChange={handleSearch}
                value={searchTerm}
                styles={styles.searchBox}
                placeholder="Find"
              />

              <PrimaryButton styles={buttonStyles.primary} text='New User' onClick={handleOpenModal} />
            </div>
          </div>
          <AddUserModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onSave={(userData) => {
              handleSaveData(userData);
              setIsModalOpen(false);
            }}
            items={items}
          />
          {items.length === 0 ? (
            <div style={styles.noDataContainer}>No data available</div>
          ) : (
            <>
               
                <DataGrid
                  items={getFilteredItems()}
                  columns={createColumns()}
                  sortable
                  getRowId={(item) => item.Id}
                  focusMode="composite"
                  style={{ minWidth: "550px" }}
                >
                  <DataGridHeader style={{ backgroundColor: "#F5F5F5" }}>
                    <DataGridRow >
                      {({ renderHeaderCell }) => (
                        <DataGridHeaderCell sortIcon={<ChevronDown20Regular />}>{renderHeaderCell()}</DataGridHeaderCell>
                      )}
                    </DataGridRow>
                  </DataGridHeader>
                  <DataGridBody>
                    {({ item, rowId }) => (
                      <DataGridRow style={{
                        fontFamily: "Segoe UI",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textAlign: "left",
                        color: "#242424"
                      }} key={rowId} onClick={() => handleRowClick(item.id)}>
                        {({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
                      </DataGridRow>
                    )}
                  </DataGridBody>
                </DataGrid>
              <div style={styles.footerContainer}>{renderPagination()}</div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const styles = {

  headerCell: {
    fontSize: "12px",
    fontWeight: 500,
    fontFamily: "Segoe UI",
    textAlign: "left",
    lineHeight: "16px",
    color: "#242424",
  },

  controlsContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },

  filterContainer: {
    position: "relative",
  },

  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  searchContainer: {
    display: "flex",
    gap: "10px",
  },
  searchBox: {
    width: "200px",
  },
  noDataContainer: {
    padding: "20px",
    textAlign: "center",
  },
  footerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
    marginLeft: '86%'
  },
  paginationContainer: {
    display: "flex",
    alignItems: "center"
  },
  pageNumberText: {
    padding: "0 5px",
  },
  arrowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  arrow: {
    fontSize: '20px',
    color: '#888',
    marginLeft: '10px',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: 'calc(100vw - 33px)'
  },
  card: {
    border: '1px solid #ddd',
    borderRadius: '4px',
    padding: '15px',
    backgroundColor: '#fff',
    cursor: 'pointer',
    transition: 'box-shadow 0.2s',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardContent: {
    flexGrow: 1,
    width: '100%',
  },
  labelValueGrid: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    gap: '5px',
    marginBottom: '5px',
  },
  label: {
    fontWeight: 'bold',
    textAlign: 'left',
  },
  value: {
    textAlign: 'left',
  },
  transparentButton: {
    root: {
      backgroundColor: 'transparent',
      border: 'none',
      color: 'black',
      size: 'medium',
    },
    rootDisabled: {
      backgroundColor: 'transparent',
      color: 'black',
      cursor: 'default',
    },
    rootHovered: {
      backgroundColor: 'transparent',
      color: 'black',
    },
  },
};

const buttonStyles = {
  primary: {
    root: { backgroundColor: '#00439D', borderColor: '#00439D' },
    rootHovered: { backgroundColor: '#003377', borderColor: '#003377' },
    rootPressed: { backgroundColor: '#002355', borderColor: '#002355' },
  },
  default: {
    root: { backgroundColor: '#FFFFFF', borderColor: '#00439D' },
    rootHovered: { backgroundColor: '#F0F0F0', borderColor: '#003377' },
    rootPressed: { backgroundColor: '#E0E0E0', borderColor: '#002355' },
  }
};

export default UserProfile;
