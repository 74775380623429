import React, { useMemo, useEffect, useState } from "react";
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  Spinner,
  tokens,
} from "@fluentui/react-components";
import { HashRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { BearerTokenAuthProvider, createApiClient } from "@microsoft/teamsfx";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { TeamsFxContext } from "./Context";
import config from "../config/config";
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import PowerBI from "./comments/PowerBI";
import CommentDetail from "./comments/CommentDetail";
import Comments from "./comments/Comment";
import UserProfile from "./comments/UserProfile";
import UserDetails from "./comments/UserDetails";
import { app } from "@microsoft/teams-js";

// Initialize Fluent UI icons
initializeIcons();

const App = () => {
  const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint,
    clientId: config.clientId,
  });

  // Define state to store context values
const [contextData, setContextData] = useState(null);

useEffect(() => {
  // Initialize the Teams SDK
  async function initializeTeams() {
    try {
      await app.initialize();
      const context = await app.getContext();
      if (context.page?.subPageId) {
        const values = context.page?.subPageId.split(',');

        // Create the JSON object
        const contextData = {
          Footprint: values[0],  
          Shift: values[1],      
          Alarms: values[3],     
          TotalDowntime: values[2], 
        };
        setContextData(contextData);
      }
    } catch (error) {
      console.error("Error initializing Teams SDK or fetching context:", error);
    }
  }

  initializeTeams();
}, []);

  // Memoize the API client creation
  const apiClient = useMemo(() => {
    if (!teamsUserCredential) return null;
    return createApiClient(
      `${config.apiEndpoint}/api/`,
      //new BearerTokenAuthProvider(async () => mockToken) // used for local dev
      new BearerTokenAuthProvider(async () => (await teamsUserCredential.getToken("")).token)
    );
  }, [teamsUserCredential]);

  // Memoize the FluentProvider theme
  const providerTheme = useMemo(() => {
    switch (themeString) {
      case "dark":
        return teamsDarkTheme;
      case "contrast":
        return teamsHighContrastTheme;
      default:
        return { ...teamsLightTheme, colorNeutralBackground3: "#eeeeee" };
    }
  }, [themeString]);

  if (loading) {
    return <Spinner style={{ margin: 100 }} />;
  }

  return (
    <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential, apiClient, contextData }}>
      <FluentProvider
        theme={providerTheme}
        style={{ background: tokens.colorNeutralBackground3 }}
      >
        <Router>
          <Routes>
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/termsofuse" element={<TermsOfUse />} />
            <Route path="/comment" element={<Comments />} />
            <Route path="/user-profile" element={<UserProfile />} />
            <Route path="/comment-detail" element={<CommentDetail />} />
            <Route path="/user-detail" element={<UserDetails />} />
            <Route path="/powerbi" element={<PowerBI />} />            
            <Route path="*" element={<Navigate to="/comment" />} />
          </Routes>
        </Router>
      </FluentProvider>
    </TeamsFxContext.Provider>
  );
};

export default App;