import React from 'react';
import { Spinner, mergeStyleSets } from '@fluentui/react';

const BackdropLoader = () => {
  const styles = mergeStyleSets({
    backdrop: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    },
  });

  return (
    <div className={styles.backdrop}>
      <Spinner size={3} label="Please wait..." />
    </div>
  );
};

export default BackdropLoader;