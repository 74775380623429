import React, { useState, useEffect, useContext } from 'react';
import {
  Modal,
  TextField,
  PrimaryButton,
  DefaultButton,
  Stack,
  Dropdown,
  IconButton,
  mergeStyleSets,
  getTheme,
  DetailsList,
  SelectionMode,
} from '@fluentui/react';
import { TeamsFxContext } from "../Context";
import BackdropLoader from "../modals/BackdropLoader"

const UserModal = ({ isOpen, onClose, onSave, mode, userData }) => {
  const { teamsUserCredential, apiClient } = useContext(TeamsFxContext);

  const [isSearchStep, setIsSearchStep] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deletedRowIds, setDeletedRowIds] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [gminId, setGminId] = useState(userData?.gmin || '');
  const [name, setName] = useState(userData?.name || '');
  const [email, setEmail] = useState(userData?.user_email || '');

  const hasMultipleRows = (userData) => {
    return Array.isArray(userData.allIds) && userData.allIds.length > 0;
  };

  const [accessRows, setAccessRows] = useState(() => {
    if (mode === 'edit' && userData) {
      if (hasMultipleRows(userData)) {
        return userData.allIds.map((id, index) => ({
          id: id,
          role: Array.isArray(userData.userrole_id) ? userData.userrole_id[index] : userData.userrole_id,
          plant: Array.isArray(userData.plant_id) ? userData.plant_id[index] : userData.plant_id,
          area: Array.isArray(userData.area_id) ? userData.area_id[index] : userData.area_id,
          department: Array.isArray(userData.department_id) ? userData.department_id[index] : userData.department_id,
          shift: Array.isArray(userData.shift) ? userData.shift[index] : userData.shift,
          subarea: Array.isArray(userData.subarea_id) ? userData.subarea_id[index] : userData.subarea_id,
          isPlantDisabled: false,
          isAreaDisabled: false,
          isDepartmentDisabled: false,
          isShiftDisabled: false,
          isSubareaDisabled: Array.isArray(userData.userrole_id)
            ? [1, 2, 4, 5, 6].includes(userData.userrole_id[index])
            : [1, 2, 4, 5, 6].includes(userData.userrole_id), filteredAreas: [],
          filteredDepartments: [],
          filteredSubareas: []
        }));
      } else {
        // Single row case
        return [{
          id: userData.id,
          role: userData.userrole_id,
          plant: userData.plant_id,
          area: userData.area_id,
          department: userData.department_id,
          shift: userData.shift,
          subarea: userData.subarea_id,
          isPlantDisabled: false,
          isAreaDisabled: false,
          isDepartmentDisabled: false,
          isShiftDisabled: false,
          isSubareaDisabled: false,
          filteredAreas: [],
          filteredDepartments: [],
          filteredSubareas: []
        }];
      }
    }

    // Default empty row for new user
    return [{
      role: '',
      plant: '',
      area: '',
      department: '',
      shift: '',
      subarea: '',
      isPlantDisabled: true,
      isAreaDisabled: true,
      isDepartmentDisabled: true,
      isShiftDisabled: true,
      isSubareaDisabled: true,
      filteredAreas: [],
      filteredDepartments: [],
      filteredSubareas: []
    }];
  });


  const [dropdownValues, setDropdownValues] = useState({
    userroles: [],
    plants: [],
    areas: [],
    departments: [],
    subareas: []
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const fetchDropdownValues = async () => {
      try {
        const values = await apiClient.get('getDefaultValues');
        console.log(values.data.data, 'drop');
        setDropdownValues(values.data.data);
      } catch (error) {
        console.error('Error fetching dropdown values:', error);
      }
    };

    fetchDropdownValues();
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const handleSearch = async () => {
    setIsSearching(true);
    try {
      const response = await apiClient.get(`searchUsers?query=${searchQuery}`);
      const results = response.data.users;
      setSearchResults(results);
    } catch (error) {
      console.error('Error searching users:', error);
    } finally {
      setIsSearching(false);
    }
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setGminId(user.id);
    setName(user.displayName);
    setEmail(user.mail);
    setIsSearchStep(false);
  };

  const handleClose = () => {
    if (mode == 'edit') {
      onClose();
      return;
    }
    setIsSearchStep(true);
    setSearchQuery('');
    setSearchResults([]);
    setSelectedUser(null);
    setGminId('');
    setName('');
    setEmail('');
    setAccessRows([
      {
        role: '',
        plant: '',
        area: '',
        department: '',
        shift: '',
        subarea: '',
        isPlantDisabled: true,
        isAreaDisabled: true,
        isDepartmentDisabled: true,
        isShiftDisabled: true,
        isSubareaDisabled: true,
        filteredAreas: [],
        filteredDepartments: [],
        filteredSubareas: []
      }
    ]);
    onClose();
  };

  const handleSave = async () => {
    setIsSaving(true);

    const userData = accessRows.map(row => {
      const baseData = {
        gminId,
        name,
        email,
        role: row.role || null,
        area: row.area || null,
        plant: row.plant || null,
        department: row.department || null,
        shift: row.shift || null,
        subarea: row.subarea || null
      };

      return row.id
        ? { ...baseData, id: row.id, action: 'update' }  // Existing row
        : { ...baseData, action: 'create' };             // New row added via "Add Row"
    });

    await onSave({
      updates: userData,
      deletedIds: deletedRowIds
    });
    setIsSaving(true);

    handleClose();
  };


  const createAccessRow = (data) => ({
    role: data?.userrole_id || '',
    plant: data?.plant_id || '',
    area: data?.area_id || '',
    department: data?.department_id || '',
    shift: data?.shift || '',
    subarea: data?.subarea_id || '',
    isPlantDisabled: data?.plant_id ? false : true,
    isAreaDisabled: data?.area_id ? false : true,
    isDepartmentDisabled: data?.department_id ? false : true,
    isShiftDisabled: data?.shift ? false : true,
    isSubareaDisabled: data?.subarea_id ? false : true,
    filteredAreas: [],
    filteredDepartments: [],
    filteredSubareas: [],
    id: data?.id
  });

  const addAccessRow = () => {
    setAccessRows([
      ...accessRows,
      createAccessRow({})
    ]);
  };

  const removeAccessRow = (index) => {
    if (accessRows.length > 1) {
      const rowToRemove = accessRows[index];
      if (rowToRemove.id) {
        setDeletedRowIds(prev => [...prev, rowToRemove.id]);
      }
      const updatedRows = accessRows.filter((_, rowIndex) => rowIndex !== index);
      setAccessRows(updatedRows);
    }
  };

  const handleRoleChange = (index, _, option) => {
    console.log(option);
    const updatedRows = [...accessRows];
    updatedRows[index] = {
      ...updatedRows[index],
      role: option.key,
      plant: '',
      area: '',
      department: '',
      shift: '',
      subarea: '',
      isPlantDisabled: false,
      isAreaDisabled: true,
      isDepartmentDisabled: true,
      isShiftDisabled: false,
      isSubareaDisabled: true,
      filteredAreas: [],
      filteredDepartments: [],
      filteredSubareas: []
    };
    setAccessRows(updatedRows);
  };

  const handlePlantChange = (index, _, option) => {
    const updatedRows = [...accessRows];
    const filteredAreas = dropdownValues.areas.filter(
      area => area.plant_id === option.key
    );
    updatedRows[index] = {
      ...updatedRows[index],
      plant: option.key,
      area: '',
      department: '',
      subarea: '',
      isAreaDisabled: false,
      isDepartmentDisabled: true,
      isShiftDisabled: true,
      isSubareaDisabled: true,
      filteredAreas,
      filteredDepartments: [],
      filteredSubareas: []
    };
    setAccessRows(updatedRows);
  };

  const handleAreaChange = (index, _, option) => {
    const updatedRows = [...accessRows];
    const filteredDepartments = dropdownValues.departments.filter(
      dept => dept.area_id === option.key
    );

    updatedRows[index] = {
      ...updatedRows[index],
      area: option.key,
      department: '',
      subarea: '',
      isDepartmentDisabled: false,
      isShiftDisabled: true,
      // isSubareaDisabled: mode !== 'edit' ? true : updatedRows[index].role === 2 || updatedRows[index].role === 1 || updatedRows[index].role === 4 || updatedRows[index].role === 5 || updatedRows[index].role === 6 ? true : false,
      isSubareaDisabled: true,
      filteredDepartments,
      filteredSubareas: []
    };
    setAccessRows(updatedRows);
  };

  const handleDepartmentChange = (index, _, option) => {
    const updatedRows = [...accessRows];
    const filteredSubareas = dropdownValues.subareas.filter(
      subarea => subarea.department_id === option.key
    );

    updatedRows[index] = {
      ...updatedRows[index],
      department: option.key,
      subarea: '',
      isShiftDisabled: false,
      isSubareaDisabled: updatedRows[index].role === 2 ||
        updatedRows[index].role === 1 ||
        updatedRows[index].role === 4 ||
        updatedRows[index].role === 5 ||
        updatedRows[index].role === 6,
      filteredSubareas
    };
    setAccessRows(updatedRows);
  };

  const handleShiftChange = (index, _, option) => {
    const updatedRows = [...accessRows];
    updatedRows[index] = {
      ...updatedRows[index],
      shift: option.key
    };
    setAccessRows(updatedRows);
  };

  const handleSubareaChange = (index, _, option) => {
    const updatedRows = [...accessRows];
    updatedRows[index] = {
      ...updatedRows[index],
      subarea: option.key
    };
    setAccessRows(updatedRows);
  };

  const theme = getTheme();
  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      width: isSearchStep && mode !== 'edit' ? (isMobile ? '90%' : '600px') : (isMobile ? '90%' : '1200px'),
      maxWidth: '100%',
      maxHeight: '90vh',
      padding: '20px',
      overflowY: 'auto',
      WebkitOverflowScrolling: 'touch',
      boxSizing: 'border-box',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
    },
    firstRow: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: '16px',
      marginBottom: '20px',
    },
    secondRow: {
      display: 'grid',
      gridTemplateColumns: 'repeat(6, 1fr)',
      gap: '16px',
      marginBottom: '20px',
      position: 'relative',
      paddingRight: '40px',
    },
    deleteButton: {
      position: 'absolute',
      right: '0',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    fieldContainer: {
      width: '100%',
    }
  });

  const columns = [
    { key: 'id', name: 'GMIN ID', fieldName: 'id', minWidth: 100 },
    { key: 'displayName', name: 'Name', fieldName: 'displayName', minWidth: 100 },
    { key: 'mail', name: 'Email', fieldName: 'mail', minWidth: 200 },
  ];

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={handleClose}
      isBlocking={true}
      containerClassName={contentStyles.container}
    >
      {isSaving && <BackdropLoader />}
      {isSearchStep && mode !== 'edit' ? (
        <Stack tokens={{ childrenGap: 15 }}>
          <div className="modal-header" style={{ display: 'flex', alignItems: 'center' }}>
            <h2>Search User</h2>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel="Close popup modal"
              onClick={handleClose}
            />
          </div>

          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Stack.Item grow>
              <TextField
                placeholder="Enter GMIN ID or User Name"
                value={searchQuery}
                onChange={(_, value) => setSearchQuery(value)}
              />
            </Stack.Item>
            <PrimaryButton text="Search" onClick={handleSearch} />
          </Stack>

          {isSearching && <BackdropLoader />}

          {searchResults.length > 0 && (
            <DetailsList
              items={searchResults}
              columns={columns}
              selectionMode={SelectionMode.single}
              onClick={handleUserSelect}
              onActiveItemChanged={handleUserSelect}
              styles={{
                root: {
                  marginTop: 0,
                  maxHeight: '400px',
                  overflowY: 'auto'
                }
              }}
            />
          )}

          <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="end">
            <DefaultButton text="Cancel" onClick={handleClose} />
          </Stack>
        </Stack>
      ) : (
        <Stack tokens={{ childrenGap: 15 }}>
          <div className={contentStyles.header}>
            <h2>{mode === 'edit' ? 'Edit User' : 'Configure User'}</h2>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel="Close popup modal"
              onClick={handleClose}
            />
          </div>

          <div className={contentStyles.firstRow}>
            <TextField
              label="GMIN ID"
              value={gminId}
              readOnly
              styles={{
                root: { width: '100%' },
                fieldGroup: { width: '100%' }
              }}
            />
            <TextField
              label="Name"
              value={name}
              readOnly
              styles={{
                root: { width: '100%' },
                fieldGroup: { width: '100%' }
              }}
            />
            <TextField
              label="Email"
              value={email}
              readOnly
              styles={{
                root: { width: '100%' },
                fieldGroup: { width: '100%' }
              }}
            />
          </div>

          {accessRows.map((row, index) => (
            <div key={index} className={contentStyles.secondRow}>
              <div className={contentStyles.fieldContainer}>
                <Dropdown
                  placeholder="Select Role"
                  label="Role"
                  selectedKey={row.role}
                  onChange={(event, option) => handleRoleChange(index, event, option)}
                  options={dropdownValues.userroles.map((role) => ({
                    key: role.userrole_id,
                    text: role.userrole_name
                  }))}
                  styles={{
                    root: { width: '100%' },
                    dropdown: { width: '100%' }
                  }}
                />
              </div>

              <div className={contentStyles.fieldContainer}>
                <Dropdown
                  placeholder="Select Plant"
                  label="Plant"
                  selectedKey={row.plant}
                  onChange={(event, option) => handlePlantChange(index, event, option)}
                  options={dropdownValues.plants.map((plant) => ({
                    key: plant.plant_id,
                    text: plant.plant_cd
                  }))}
                  disabled={row.isPlantDisabled}
                  styles={{
                    root: { width: '100%' },
                    dropdown: { width: '100%' }
                  }}
                />
              </div>

              <div className={contentStyles.fieldContainer}>
                <Dropdown
                  placeholder="Select Area"
                  label="Area"
                  selectedKey={row.area}
                  onChange={(event, option) => handleAreaChange(index, event, option)}
                  options={mode === 'edit' ?
                    dropdownValues.areas.map((area) => ({
                      key: area.area_id,
                      text: area.area
                    })) :
                    row.filteredAreas.map((area) => ({
                      key: area.area_id,
                      text: area.area
                    }))}
                  disabled={row.isAreaDisabled}
                  styles={{
                    root: { width: '100%' },
                    dropdown: { width: '100%' }
                  }}
                />
              </div>

              <div className={contentStyles.fieldContainer}>
                <Dropdown
                  placeholder="Select Department"
                  label="Department"
                  selectedKey={row.department}
                  onChange={(event, option) => handleDepartmentChange(index, event, option)}
                  options={mode === 'edit' ?
                    dropdownValues.departments.map((department) => ({
                      key: department.department_id,
                      text: department.department
                    })) :
                    row.filteredDepartments.map((department) => ({
                      key: department.department_id,
                      text: department.department
                    }))}
                  disabled={row.isDepartmentDisabled}
                  styles={{
                    root: { width: '100%' },
                    dropdown: { width: '100%' }
                  }}
                />
              </div>

              <div className={contentStyles.fieldContainer}>
                <Dropdown
                  placeholder="Select Shift"
                  label="Shift"
                  selectedKey={row.shift}
                  onChange={(event, option) => handleShiftChange(index, event, option)}
                  options={[
                    { key: 1, text: '1' },
                    { key: 2, text: '2' },
                    { key: 3, text: '3' }
                  ]}
                  styles={{
                    root: { width: '100%' },
                    dropdown: { width: '100%' }
                  }}
                />
              </div>

              <div className={contentStyles.fieldContainer}>
                <Dropdown
                  placeholder="Select Subarea"
                  label="Subarea"
                  selectedKey={row.subarea}
                  onChange={(event, option) => handleSubareaChange(index, event, option)}
                  options={mode === 'edit' ?
                    dropdownValues.subareas.map((subarea) => ({
                      key: subarea.subarea_id,
                      text: subarea.sub_area
                    })) :
                    row.filteredSubareas.map((subarea) => ({
                      key: subarea.subarea_id,
                      text: subarea.sub_area
                    }))}
                  disabled={row.isSubareaDisabled}
                  styles={{
                    root: { width: '100%' },
                    dropdown: { width: '100%' }
                  }}
                />
              </div>

              {index > 0 && (
                <div className={contentStyles.deleteButton}>
                  <IconButton
                    iconProps={{ iconName: 'Delete' }}
                    title="Delete Row"
                    onClick={() => removeAccessRow(index)}
                    styles={{
                      root: {
                        padding: '0',
                        minWidth: '32px',
                        height: '32px'
                      }
                    }}
                  />
                </div>
              )}
            </div>
          ))}

          <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="end">
            <PrimaryButton text="Add Row" onClick={addAccessRow} />
            <DefaultButton text="Cancel" onClick={handleClose} />
            <PrimaryButton text="Save" onClick={handleSave} />
          </Stack>
        </Stack>
      )
      }
    </Modal >
  );
};

export default UserModal;