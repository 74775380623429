import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import config from "../config/config";

const speechConfig = SpeechSDK.SpeechConfig.fromEndpoint(
  new URL(config.sttEndpoint),
  config.speechKey,
);

let recognizer;

export const recognizeSpeech = () => {
    return new Promise((resolve, reject) => {
      const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
      recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);

      recognizer.recognized = (s, e) => {
        if (e.result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
          console.log("Recognized: ", e.result.text);
          resolve(e.result.text); 
        } else if (e.result.reason === SpeechSDK.ResultReason.NoMatch) {
          console.error("No speech could be recognized.");
        } else {
          console.error("Recognition Error:", e.result.errorDetails);
        }
      };
  
      recognizer.startContinuousRecognitionAsync((result) => {
        if (result !== SpeechSDK.ResultReason.Success) {
          reject(`Could not start continuous recognition: ${result}`);
        }
      }, (error) => {
        console.error("Error starting recognition:", error);
        reject(error);
      });
    });
  };
  
  // Function to stop continuous recognition
export const stopContinuousRecognitionAsync = () => {
  return new Promise((resolve, reject) => {
    if (recognizer) {
      recognizer.stopContinuousRecognitionAsync(() => {
        console.log("Stopped recognition.");
        resolve();
      }, (error) => {
        console.error("Error stopping recognition:", error);
        reject(error);
      });
    } else {
      resolve();
    }
  });
};