import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import config from "../config/config";

const speechConfig = SpeechSDK.SpeechConfig.fromEndpoint(
  new URL(config.ttsEndpoint),
  config.speechKey,
);

speechConfig.speechSynthesisVoiceName = "en-US-JennyNeural";

export const synthesizeSpeech = (text) => {
  return new Promise((resolve, reject) => {
    const synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig);
    const speechSynthesisText = text; 

    synthesizer.speakTextAsync(
      speechSynthesisText,
      (result) => {
        if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
          console.log("Speech synthesized successfully.");
          resolve(); // Resolve when speech synthesis is done
        } else {
          console.error("Speech synthesis error:", result.errorDetails);
          reject(result.errorDetails);
        }
        synthesizer.close();
      },
      (error) => {
        console.error("Speech synthesis error:", error);
        reject(error);
        synthesizer.close();
      }
    );
  });
};