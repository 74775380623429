import React, { useState, useEffect, useContext, useMemo, useCallback } from "react";
import { TeamsFxContext } from "../Context";
import { useLocation, useNavigate } from "react-router-dom";
import {
  TableCellLayout,
  Label,  
  DataGrid,
  DataGridBody,
  DataGridRow,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridCell,
  createTableColumn,
  SearchBox,
  Spinner,
  MessageBar,
} from "@fluentui/react-components";
import FilterDropdown from './FilterDropdown';
import { IconButton, Text, PrimaryButton } from "@fluentui/react";
import AddCommentModal from "../modals/CommentModal";
import { ChevronDown20Regular } from "@fluentui/react-icons";

const Comments = () => {
  const { teamsUserCredential, apiClient, isMobile, contextData } = useContext(TeamsFxContext);  
  const location = useLocation();
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState({});
  const [items, setItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  //const [itemsPerPage] = useState(10);
  const itemsPerPage = 10;  
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [appliedFilters, setAppliedFilters] = useState({
    shifts: [],
    footprints: [],
    users: [],
    downtimeMin: "",
    downtimeMax: "",
    alarmMin: "",
    alarmMax: ""
  }); 
  
  const fetchItems = useCallback(async () => {
    try {
      const response = await apiClient.get("userComments");
      setItems(response.data.comments || []);
    } catch (err) {
      console.error("Error fetching comments:", err);
      setErrorMessage("Failed to fetch comments. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  }, [apiClient]);

  useEffect(() => {
    const initUserInfo = async () => {
      try {
        const userInfo = await teamsUserCredential.getUserInfo();
        setUserInfo(userInfo);        
        setAppliedFilters((prevFilters) => ({
          ...prevFilters,
          users: [userInfo.displayName] 
        }));


        const state = location.state;
        if (state?.items) {
          setItems(state.items);
          setAppliedFilters(state.appliedFilters);
          setCurrentPage(state.currentPage || 1);
          setSearchTerm(state.searchTerm || "");
        } else {
          await fetchItems();
        }
        if(contextData){
          setIsEditModalOpen(true);
        }
      } catch (err) {
        console.error("Error initializing or fetching data:", err);
        setErrorMessage("Error fetching data. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    initUserInfo();
  }, [teamsUserCredential, location.state, fetchItems]);

  const createColumns = useMemo(
    () =>
      [
        { id: "Shift", label: "Shift" },
        { id: "Footprint", label: "Footprint" },
        { id: "Alarms", label: "Alarms" },
        { id: "TotalDowntime", label: "Total DT" },
        { id: "User", label: "User" },
        { id: "Comment", label: "Comment" },
      ].map((column) =>
        createTableColumn({
          columnId: column.id,
          compare: (a, b) => a[column.id].localeCompare(b[column.id]),
          renderHeaderCell: () => <span style={styles.headerCell}>{column.label}</span>,
          renderCell: (item) => <TableCellLayout>{item[column.id]}</TableCellLayout>,
        })
      ),
    []
  );
  
  const handleOpenModal = useCallback(() => setIsModalOpen(true), []);
  const handleCloseModal = useCallback(() => setIsModalOpen(false), []);

  const handleRowClick = useCallback(
    (rowId) => {
      const selectedItem = items.find((item) => item.Id === rowId);
      navigate("/comment-detail", {
        state: {
          comment: selectedItem,
          currentPage,
          searchTerm,
          items,
          currentuser: userInfo.displayName,
          appliedFilters,
        },
        replace: true,
      });
    },
    [navigate, items, currentPage, searchTerm, userInfo.displayName, appliedFilters]
  );

  const handleSearch = useCallback((event) => {
    setSearchTerm(event.target.value?.toLowerCase() || "");
    setCurrentPage(1);
  }, []);

  const handlePagination = useCallback((direction) => {
    setCurrentPage((prevPage) => prevPage + direction);  
  }, []);

  const filteredItems = useMemo(() => {
    return items.filter((item) => {
      const matchesSearch = Object.values(item).some((val) =>
        String(val).toLowerCase().includes(searchTerm)
      );

      const matchesFilters =
        (appliedFilters.shifts.length === 0 || appliedFilters.shifts.includes(item.Shift)) &&
        (appliedFilters.footprints.length === 0 ||
          appliedFilters.footprints.includes(item.Footprint)) &&
        (appliedFilters.users.length === 0 || appliedFilters.users.includes(item.User)) &&
        (appliedFilters.downtimeMin === "" ||
          parseInt(item.TotalDowntime) >= parseInt(appliedFilters.downtimeMin)) &&
        (appliedFilters.downtimeMax === "" ||
          parseInt(item.TotalDowntime) <= parseInt(appliedFilters.downtimeMax)) &&
        (appliedFilters.alarmMin === "" ||
          parseInt(item.Alarms) >= parseInt(appliedFilters.alarmMin)) &&
        (appliedFilters.alarmMax === "" ||
          parseInt(item.Alarms) <= parseInt(appliedFilters.alarmMax));

      return matchesSearch && matchesFilters;
    });
  }, [items, searchTerm, appliedFilters]);


  // const getFilteredItems = () => {
  //   // First, filter items based on searchTerm and appliedFilters
  //   const filteredItems = items.filter((item) => {
  //     const matchesSearch = Object.values(item).some((val) =>
  //       String(val).toLowerCase().includes(searchTerm.toLowerCase())
  //     );
  
  //     const matchesFilters =
  //       (appliedFilters.shifts?.length === 0 || appliedFilters.shifts?.includes(item.Shift)) &&
  //       (appliedFilters.footprints?.length === 0 || appliedFilters.footprints?.includes(item.Footprint)) &&
  //       (appliedFilters.users?.length === 0 || appliedFilters.users?.includes(item.User)) &&
  //       (appliedFilters.downtimeMin === "" || parseInt(item.TotalDowntime) >= parseInt(appliedFilters.downtimeMin)) &&
  //       (appliedFilters.downtimeMax === "" || parseInt(item.TotalDowntime) <= parseInt(appliedFilters.downtimeMax)) &&
  //       (appliedFilters.alarmMin === "" || parseInt(item.Alarms) >= parseInt(appliedFilters.alarmMin)) &&
  //       (appliedFilters.alarmMax === "" || parseInt(item.Alarms) <= parseInt(appliedFilters.alarmMax));
  
  //     return matchesSearch && matchesFilters;
  //   });
  
  //   // Then, apply pagination
  //   const startIndex = (currentPage - 1) * itemsPerPage;
  //   return filteredItems.slice(startIndex, startIndex + itemsPerPage);
  // };

  const pagedItems = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredItems.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredItems, currentPage]);

  const totalPages = useMemo(
    () => Math.ceil(filteredItems.length / itemsPerPage),
    [filteredItems.length]
  );

  const handleApplyFilters = useCallback((filters) => {
    setAppliedFilters(filters);
    setCurrentPage(1);
  }, []);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        await apiClient.post("userComments", data);
        await fetchItems();
        setIsModalOpen(false);
        setIsEditModalOpen(false);
      } catch (err) {
        console.error("Error saving comment:", err);
        setErrorMessage("Failed to save comment. Please try again.");
      }
    },
    [apiClient, fetchItems]
  );

  const renderPagination = () => (
    <div style={styles.paginationContainer}>
      <IconButton
        iconProps={{ iconName: "ChevronLeftEnd6" }}
        ariaLabel="Go to first page"
        disabled={currentPage === 1}
        onClick={() => handlePagination(-currentPage + 1)}
        styles={styles.transparentButton}
      />
      <IconButton
        iconProps={{ iconName: "Back" }}
        ariaLabel="Go to previous page"
        disabled={currentPage === 1}
        onClick={() => handlePagination(-1)}
        styles={styles.transparentButton}
      />
      <Text style={styles.pageNumberText}>Page {currentPage}</Text>
      <IconButton
        iconProps={{ iconName: "Forward" }}
        ariaLabel="Go to next page"
        disabled={currentPage === totalPages}
        onClick={() => handlePagination(1)}
        styles={styles.transparentButton}
      />
      <IconButton
        iconProps={{ iconName: "ChevronRightEnd6" }}
        ariaLabel="Go to last page"
        disabled={currentPage === totalPages}
        onClick={() => handlePagination(totalPages - currentPage)}
        styles={styles.transparentButton}
      />
    </div>
  );

  // if (navigateToDetail) {
  //   return (
  //     <Navigate
  //       to="/comment-detail"
  //       state={{
  //         comment: selectedComment,
  //         currentPage,
  //         searchTerm,
  //         items,
  //         currentuser: userInfo.displayName,
  //         appliedFilters
  //       }}
  //       replace
  //     />
  //   );
  // }

  return (
    <div style={{ padding: "20px", background: "white" }}>
      {isLoading && <Spinner label="Loading comments..." size="large" />}
      {!isLoading && errorMessage && (
        <MessageBar intent="error" isMultiline={false} dismissButtonAriaLabel="Close">
          {errorMessage}
        </MessageBar>
      )}
      {!isLoading && !errorMessage && (
        <>
          <div style={styles.headerContainer}>
            <Label style={styles.headerLabel}>Comments</Label>
            <div style={styles.searchContainer}>
              <FilterDropdown
                items={items}
                onApplyFilters={handleApplyFilters}
                currentuser={userInfo.displayName}
                appliedFilters={appliedFilters}
                setAppliedFilters={setAppliedFilters}
              />
              <SearchBox
                onChange={handleSearch}
                value={searchTerm}
                styles={styles.searchBox}
                placeholder="Find"
              />
              <PrimaryButton
                styles={buttonStyles.primary}
                text="New Comment"
                onClick={handleOpenModal}
              />
            </div>
          </div>
          <AddCommentModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            onSave={handleSaveData}
            user={userInfo.displayName}
          />
          <AddCommentModal
            isOpen={isEditModalOpen}
            onClose={() => setIsEditModalOpen(false)}
            onSave={handleSaveData}
            comment={contextData}
            user={userInfo.displayName}
            mode="edit"
            chat={true}
          />
          {items.length === 0 ? (
            <div style={styles.noDataContainer}>No data available</div>
          ) : (
            <>
              {isMobile ? (
                <div style={styles.cardContainer}>
                  {pagedItems.map((item) => (
                    <div
                      key={item.Id}
                      style={styles.card}
                      onClick={() => handleRowClick(item.Id)}
                    >
                      <div style={styles.cardContent}>
                        <div style={styles.labelValueGrid}>
                          <label style={styles.label}>Shift</label>
                          <span style={styles.value}>{item.Shift}</span>
                          <label style={styles.label}>Footprint</label>
                          <span style={styles.value}>{item.Footprint}</span>
                          <label style={styles.label}>Comment</label>
                          <span style={styles.value}>{item.Comment}</span>
                        </div>
                      </div>
                      <div style={styles.arrowContainer}>
                        <span style={styles.arrow}>→</span>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <DataGrid
                  items={pagedItems}
                  columns={createColumns}
                  sortable
                  getRowId={(item) => item.Id}
                  focusMode="composite"
                  style={{ minWidth: "550px" }}
                >
                  <DataGridHeader style={{ backgroundColor: "#F5F5F5" }}>
                    <DataGridRow>
                      {({ renderHeaderCell }) => (
                        <DataGridHeaderCell sortIcon={<ChevronDown20Regular />}>
                          {renderHeaderCell()}
                        </DataGridHeaderCell>
                      )}
                    </DataGridRow>
                  </DataGridHeader>
                  <DataGridBody>
                    {({ item, rowId }) => (
                      <DataGridRow
                        style={styles.dataGridRow}
                        key={rowId}
                        onClick={() => handleRowClick(rowId)}
                      >
                        {({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
                      </DataGridRow>
                    )}
                  </DataGridBody>
                </DataGrid>
              )}
              <div style={styles.footerContainer}>{renderPagination()}</div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const styles = {
  headerCell: {
    fontSize: "12px",
    fontWeight: 500,
    fontFamily: "Segoe UI",
    textAlign: "left",
    lineHeight: "16px",
    color: "#242424",
  },
  headerLabel: {
    fontWeight: 400,
    fontSize: "18px",
    textAlign: "left",
    fontFamily: "Roboto",
    color: "#3B3B39",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  searchContainer: {
    display: "flex",
    gap: "10px",
  },
  searchBox: {
    width: "200px",
  },
  noDataContainer: {
    padding: "20px",
    textAlign: "center",
  },
  footerContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: "10px",
  },
  paginationContainer: {
    display: "flex",
    alignItems: "center",
  },
  pageNumberText: {
    padding: "0 5px",
  },
  arrowContainer: {
    display: "flex",
    alignItems: "center",
  },
  arrow: {
    fontSize: "20px",
    color: "#888",
    marginLeft: "10px",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width: "100%",
  },
  card: {
    border: "1px solid #ddd",
    borderRadius: "4px",
    padding: "15px",
    backgroundColor: "#fff",
    cursor: "pointer",
    transition: "box-shadow 0.2s",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardContent: {
    flexGrow: 1,
    width: "100%",
  },
  labelValueGrid: {
    display: "grid",
    gridTemplateColumns: "100px 1fr",
    gap: "5px",
    marginBottom: "5px",
  },
  label: {
    fontWeight: "bold",
    textAlign: "left",
  },
  value: {
    textAlign: "left",
  },
  dataGridRow: {
    fontFamily: "Segoe UI",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    textAlign: "left",
    color: "#242424",
  },
  transparentButton: {
    root: {
      backgroundColor: "transparent",
      border: "none",
      color: "black",
      size: "medium",
    },
    rootDisabled: {
      backgroundColor: "transparent",
      color: "black",
      cursor: "default",
    },
    rootHovered: {
      backgroundColor: "transparent",
      color: "black",
    },
  },
};

const buttonStyles = {
  primary: {
    root: { backgroundColor: "#00439D", borderColor: "#00439D" },
    rootHovered: { backgroundColor: "#003377", borderColor: "#003377" },
    rootPressed: { backgroundColor: "#002355", borderColor: "#002355" },
  },
};

export default Comments;